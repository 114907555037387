import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LinkExpiry from './views/Pages/LinkExpiry';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Pages
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
const DocShare = React.lazy(() => import("./views/DocShare"));
const LiveShare = React.lazy(() => import("./views/LiveShare"));


class App extends Component {
  componentDidMount() { }

  render() {
    return (
      <React.Fragment>
        <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/:businessId/document/:code"
                name="Share"
                render={props => <DocShare {...props} />}
              />
              <Route
                exact
                path="/:businessId/live/:code"
                name="LiveShare"
                render={props => <LiveShare {...props} />}
              />
              <Route
                exact
                path="/linkexpired"
                name="Link Expiry"
                render={props => <LinkExpiry {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={props => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={props => <Page500 {...props} />}
              />
            </Switch>
          </React.Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(App);
