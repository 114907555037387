import React from 'react'
import {
  Col,
  Row
} from "reactstrap";
// import { Link } from 'react-router-dom';
import Img from '../../../assets/img/warning.png';

function LinkExpiry() {
  return (
    <Row
      className="justify-content-center"
      style={{ margin: 0, textAlign: "center" }}>

      {/* <Col md="12" style={{ textAlign: "center", marginTop: "10vh" }}> */}
      <Row style={{ width: "90%", marginTop: "15vh", textAlign: "center" }}>

        <Col lg="12" md="12" xs="12">
          <img src={Img} alt="Access-Denied logo" style={{ width: "25vh" }} />
          <h3 className="pt-4 pb-3" style={{ fontSize: "6vh" }}>Your link is not working!</h3>
        </Col>
      </Row>


      <Row>

        <Col />
        <Col lg="12" md="12" style={{ width: "90%", }}>
          <div style={{ textAlign: "left", }}>

            <p className="text-muted " style={{ fontSize: "4vh", marginBottom: "10px" }}>
              Possible reasons :
  </p>
            <ol>
              <p className="text-muted " style={{ fontSize: "3vh" }}><li>Your link has been expired.</li></p>
              <p className="text-muted " style={{ fontSize: "3vh" }}><li>The link you have entered is incorrect.</li></p>
            </ol>

          </div>
        </Col>
        <Col />
      </Row>
      {/* 
      </Col> */}
    </Row >
  );
}
export default LinkExpiry;
